// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-equipe-js": () => import("./../../../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-parceiros-js": () => import("./../../../src/pages/parceiros.js" /* webpackChunkName: "component---src-pages-parceiros-js" */),
  "component---src-pages-projetos-js": () => import("./../../../src/pages/projetos.js" /* webpackChunkName: "component---src-pages-projetos-js" */),
  "component---src-pages-publicacoes-js": () => import("./../../../src/pages/publicacoes.js" /* webpackChunkName: "component---src-pages-publicacoes-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

